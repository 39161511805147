import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import store from './store'
import vuetify from './plugins/vuetify';
import '@/plugins/firebaseConfig'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.config.productionTip = false
library.add(fas);
library.add(fab);
library.add(far);
Vue.component('font-awesome-icon', FontAwesomeIcon);

let app;
if(!app) {
  app = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
}