import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import styles from "../styles/styles.css";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

Vue.component('font-awesome-icon', FontAwesomeIcon); // Register component globally
library.add(fas); // Include needed icons

Vue.use(Vuetify);
Vue.use(styles);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                text: '#f96767',
                primary: '#f96767',
                secondary: '#ffffff',
                accent: '#3ec6c6',
                grey: '#9f9e9e',
                error: '#ffffff'
            }
        }
    },
    icons: {
        iconfont: 'fa',
    },
});