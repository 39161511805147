<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script>
export default {
    name: 'App',
    data: () => ({}),
    created() {
        console.log("App created!");
    }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import "./styles/styles.css";
@import "./styles/box-shadows.css";
</style>