import firebase from "firebase/app";
import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'

let firebaseConfig = {
    apiKey: "AIzaSyDcMj47A_di0mrF2z0QIT6J-9JCFZFjVBI",
    authDomain: "healthywoapp.firebaseapp.com",
    databaseURL: "https://healthywoapp.firebaseio.com",
    projectId: "healthywoapp",
    storageBucket: "healthywoapp.appspot.com",
    messagingSenderId: "623084791653",
    appId: "1:623084791653:web:e6f55b00cc1ed97012238c",
    measurementId: "G-46PJ6C73MX"
};


firebase.initializeApp(firebaseConfig);

if(location.hostname === 'localhost') {

    console.log('%c ~~~~ WELCOME HOME KUIDA DEVELOPER ~~~~ ', 'background: #f96767; color: #fff; padding:40px 80px; border-radius: 5px; margin: 10px 0;');
    console.log('%c You are running Kuida Doctors app locally ', 'background: #3ec6c6; color: #fff; padding:20px 70px; border-radius: 5px;');


} else {
    console.log('%c ~~~~ WELCOME TO KUIDA DEAR NOSY ~~~~ ', 'background: #f96767; color: #fff; padding:40px 80px; border-radius: 5px; margin: 10px 0;');
    console.log('%c If you can see this it means you got curious about our work. We invite you to apply to join us, send your resume to vacantes@ideen.mx ', 'background: #3ec6c6; color: #fff; padding:20px 70px; border-radius: 5px;');
}



firebase.analytics();
export const db = firebase.firestore();
