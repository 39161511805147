<!--suppress ALL -->
<template>
    <v-app>
        <!-- DESKTOP NAVBAR-->
        <v-app-bar class="hidden-sm-and-down" app color="primary" dark flat id="navbar">
            <img class="mr-3" :src="require('../assets/Kuida_logo.png')" height="50"/>

            <v-toolbar-items>
                <v-btn plain small @click="$vuetify.goTo('#jumbotron-div')" style="padding-left: 30px;">INICIO</v-btn>
                <v-btn plain small @click="$vuetify.goTo('#doctors-div')">ESPECIALISTAS</v-btn>
                <v-btn plain small @click="$vuetify.goTo('#download-div')">NOSOTROS</v-btn>
                <v-btn plain small @click="$vuetify.goTo('#pricing-div')">PRECIOS</v-btn>
                <v-btn plain small @click="$vuetify.goTo('#users-div')">PACIENTES</v-btn>
                <v-btn plain small href="https://kuida-docs.web.app/" target="_blank">DOCUMENTACIÓN</v-btn>
            </v-toolbar-items>

            <v-spacer></v-spacer>

            <v-toolbar-items>
                <v-btn plain small href="https://doctors.kuida.io/" target="_blank">APP DOCTORES</v-btn>
                <v-btn plain small href="https://app.kuida.io/" target="_blank">APP USUARIOS</v-btn>
            </v-toolbar-items>
        </v-app-bar>

        <!-- DESKTOP CONTENT -->
        <v-main class="hidden-sm-and-down">
            <!-- JUMBOTRON -->
            <v-container class="mt-n16" style="background-color: #f96767; padding: 0 100px; height: 700px;" fill-height
                         fluid id="jumbotron-div">
                <v-row justify="center" align="center">
                    <v-col style="padding-top: 50px;">
                        <v-container>
                            <h1 id="slogan">La plataforma perfecta para los especialistas exitosos</h1>
                            <h2 class="over-dark-content mt-6">
                                Ayudamos a los profesionales de la salud a digitalizar las tareas de su consultorio y brindarle a sus pacientes atención personalizada.
                            </h2>
                        </v-container>
                    </v-col>
                    <v-col>
                        <img class="mr-3" :src="require('../assets/art/doctors.jpg')" height="350"/>
                    </v-col>
                </v-row>
            </v-container>

            <!-- DOCTORS APP INFO DIV -->
            <v-container
                fluid
                style="height: 700px; background-color: #5AC6C8; padding: 0 100px;"
                fill-height
                id="doctors-div"
            >
                <h1
                    class="subtitles over-dark-content"
                >ESPECIALISTAS</h1>

                <v-row class="justify-center">
                    <!-- AGENDA -->
                    <v-col cols="12" md="4" align="center" class="info-card-col pa-6">
                        <img
                            class="mb-6 svg"
                            src="../assets/icons/calendar-check-regular.svg"
                            height="96"
                        >
                        <h2 class="over-dark-content mb-3">AGENDA</h2>
                        <p class="over-dark-content content-text ">
                            Kuida potencializa la calidad del servicio para tus pacientes con la mejor
                            programación de citas y recordatorios para ayudar a gestionar el flujo de pacientes.
                        </p>
                    </v-col>

                    <!-- ACCESIBILIDAD -->
                    <v-col cols="12" md="4" align="center" class="info-card-col pa-6">
                        <img
                            class="mb-6 svg"
                            src="../assets/icons/thumbs-up-regular.svg"
                            height="96"
                        >
                        <h2 class="over-dark-content mb-3">ACCESIBILIDAD</h2>
                        <p class="over-dark-content content-text ">
                            Obtén actualizaciones en tiempo real y modifica los horarios de atención disponibles
                            desde cualquier dispositivo.
                        </p>
                    </v-col>

                    <!-- EXPEDIENTE INTELIGENTE -->
                    <v-col cols="12" md="4" align="center" class="info-card-col pa-6">
                        <img
                            class="mb-6 svg"
                            src="../assets/icons/users-solid.svg"
                            height="96"
                        >
                        <h2 class="over-dark-content mb-3">EXPEDIENTE INTELIGENTE</h2>
                        <p class="over-dark-content content-text ">
                            Administramos todos tus expedientes para que estén disponibles en
                            el momento exacto de tus consultas. A un click de distancia sin tener que buscarlos.
                        </p>
                    </v-col>
                </v-row>

                <!-- CTA BUTTON -->
                <v-row class="justify-center">
                    <a href="https://kuidadoctors.web.app" target="_blank">
                        <button
                            class="kuida-big-btn coral-btn button-shadow mt-n16 mb-16"
                            id="doctors-button"
                        >
                            Comenzar
                        </button>
                    </a>
                </v-row>
            </v-container>

            <!-- APP KEYNOTE DIV -->
            <v-container fluid style="height: 800px; background-color: white; padding: 0 100px;" fill-height id="download-div">
                <v-row align="center" justify="center" class="mt-4">
                    <h1 style="color: #5AC6C8;">CONOCE KUIDA</h1>

                    <iframe
                        style="width: 80%; height: 60vh;"
                        src="https://www.youtube.com/embed/lET7kZlnAUw"
                        title="Keynote Kuida"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </v-row>

                <v-row align="center" justify="center" class="mt-12 mb-12">
                    <v-col cols="2"></v-col>
                    <v-col cols="4" align="center">
                        <v-row align="center" justify="center">
                            <a href="https://kuida-users.web.app" target="_blank">
                                <button
                                    class="no-uppercase kuida-big-btn coral-btn button-shadow"
                                    id="download-button"
                                >
                                    App para Pacientes
                                </button>
                            </a>
                        </v-row>
                    </v-col>
                    <v-col cols="4" align="center">
                        <v-row align="center" justify="center">
                            <a href="https://kuidadoctors.web.app" target="_blank">
                                <button
                                    class="kuida-big-btn aqua-btn button-shadow"
                                    id="doctors-button"
                                >
                                    App para Especialistas
                                </button>
                            </a>
                        </v-row>
                    </v-col>
                    <v-col cols="2"></v-col>
                </v-row>
            </v-container>

            <!-- DOCTORS PRICES AND COMMISSIONS DIV -->
            <v-container fluid style="height: 700px; background-color: #5AC6C8; padding: 0 100px;" fill-height id="pricing-div">
                <h1 class="subtitles over-dark-content">PRECIOS</h1>

                <v-row class="justify-center mb-4">
                    <!-- STANDARD SUBSCRIPTION -->
                    <v-col cols="12" md="4" align="center" class="info-card-col pa-6">
                        <v-card class="mx-auto pt-6 align-content-center" width="300" height="300"
                                style="border-radius: 15px; align-content: center;">
                            <p class="subscription-title">
                                STANDARD
                            </p>

                            <p class="subscription-price">
                                $499.00 <span style="font-size: medium">MXN</span>
                            </p>

                            <p class="subscription-fee">
                                + 5% COMISIÓN POR CITA
                            </p>

                            <p class="subscription-time">
                                POR 3 MESES
                            </p>

                            <p class="subscription-period">
                                ( PAGO TRIMESTRAL )
                            </p>
                        </v-card>
                    </v-col>

                    <!-- PREMIERE SUBSCRIPTION -->
                    <v-col cols="12" md="4" align="center" class="info-card-col pa-6">
                        <v-card class="mx-auto pt-6 align-content-center" width="300" height="300"
                                style="border-radius: 15px; align-content: center;">
                            <p class="subscription-title">
                                PREMIERE
                            </p>

                            <p class="subscription-price">
                                $1,399.00 <span style="font-size: medium">MXN</span>
                            </p>

                            <p class="subscription-fee">
                                + 5% COMISIÓN POR CITA
                            </p>

                            <p class="subscription-time">
                                POR 12 MESES
                            </p>

                            <p class="subscription-period">
                                ( PAGO ANUAL )
                            </p>

                            <v-row class="mt-4" align="center" justify="center">
                                <v-btn color="grey" text @click="showPremiere = !showPremiere">
                                    BONOS POR REFERENCIAS
                                </v-btn>
                            </v-row>

                            <v-expand-transition>
                                <div v-show="showPremiere" style="background-color: white; z-index: 1000;">
                                    <v-card-text>
                                        Obten $100.00 MXN de bono por cada especialista que invites y contrate una suscripción Premiere o $1,000.00 MXN si contrata Premiere+.
                                    </v-card-text>
                                </div>
                            </v-expand-transition>
                        </v-card>
                    </v-col>

                    <!-- PREMIERE + SUBSCRIPTION -->
                    <v-col cols="12" md="4" align="center" class="info-card-col pa-6">
                        <v-card class="mx-auto pt-6 align-content-center" width="300" height="300"
                                style="border-radius: 15px; align-content: center;">
                            <p class="subscription-title">
                                PREMIERE +
                            </p>

                            <p class="subscription-price">
                                $18,999.00 <span style="font-size: medium">MXN</span>
                            </p>

                            <p class="subscription-fee">
                                SIN COMISIÓNES POR CITA
                            </p>

                            <p class="subscription-time">
                                POR 12 MESES
                            </p>

                            <p class="subscription-period">
                                ( PAGO ANUAL )
                            </p>

                            <v-row class="mt-4" align="center" justify="center">
                                <v-btn color="grey" text @click="showPremierePlus = !showPremierePlus">
                                    BONOS POR REFERENCIAS
                                </v-btn>
                            </v-row>

                            <v-expand-transition>
                                <div v-show="showPremierePlus" style="background-color: white; z-index: 1000;">
                                    <v-card-text>
                                        Obten $1,000.00 MXN de bono por cada especialista que invites y contrate una suscripción Premiere+ o $100.00 MXN si contrata Premiere.
                                    </v-card-text>
                                </div>
                            </v-expand-transition>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row class="justify-center">
                    <a href="https://kuidadoctors.web.app" target="_blank">
                        <button
                            class="kuida-big-btn coral-btn button-shadow mt-n16 mb-16"
                            id="doctors-button"
                        >
                            Registrate
                        </button>
                    </a>
                </v-row>
            </v-container>

            <!-- USERS APP INFO DIV -->
            <v-container
                fluid style="height: 700px; background-color: #f96767; padding: 0 100px;"
                fill-height
                id="users-div"
            >
                <h1 class="subtitles over-dark-content">PACIENTES</h1>
                <v-row class="justify-center">
                    <!-- SEGURIDAD -->
                    <v-col cols="12" md="4" align="center" class="info-card-col pa-6">
                        <img
                            class="mb-6 svg"
                            src="../assets/icons/user-shield-solid.svg"
                            height="96"
                        >
                        <h2 class="over-dark-content mb-3">SEGURIDAD</h2>
                        <p class="over-dark-content content-text ">
                            Accede a información confiable y profesionales de la salud con perfiles
                            previamente varificados. Podrás ver su perfil completo, certificaciones y consultorios.
                        </p>
                    </v-col>

                    <!-- VELOCIDAD -->
                    <v-col cols="12" md="4" align="center" class="info-card-col pa-6">
                        <img
                            class="mb-6 svg"
                            src="../assets/icons/calendar-alt-regular.svg"
                            height="96"
                        >
                        <h2 class="over-dark-content mb-3">VELOCIDAD</h2>
                        <p class="over-dark-content content-text ">
                            Agenda, reagenda o cancela tus citas en menos de 30 segundos con nuestros
                            profesionales desde cualquier dispositivo.
                        </p>
                    </v-col>

                    <!-- EFICIENCIA -->
                    <v-col align="center" md="4" class="info-card-col pa-6">
                        <img
                            class="mb-6 svg"
                            src="../assets/icons/apple-alt-solid.svg"
                            height="96"
                        >
                        <h2 class="over-dark-content mb-3">EFICIENCIA</h2>
                        <p class="over-dark-content content-text ">
                            Escoge la mejor opción en tu zona para tus necesidades, obten recordatorios
                            para nunca faltar a tus consultas y lleva el siguimiento de tus síntomas
                            o tratamientos desde la misma aplicación.
                        </p>
                    </v-col>
                </v-row>
                <v-row class="justify-center">
                    <a href="https://kuida-users.web.app" target="_blank">
                        <button
                            class="no-uppercase kuida-big-btn aqua-btn button-shadow mt-n16 mb-16"
                            id="download-button"
                        >
                            Conoce nuestra app
                        </button>
                    </a>
                </v-row>
            </v-container>

            <!-- FOOTER -->
            <v-footer padless style="height: 350px;">
                <v-col fill-height>
                    <v-row align="center" justify="center" style="padding-top: 50px;">
                        <a href="https://www.instagram.com/kuida.io/?hl=es-la" target="_blank" style="margin: 0 10px;">
                            <font-awesome-icon :icon="['fab','instagram']" size="2x"/>
                        </a>
                        <a href="#" target="_blank" style="margin: 0 10px;">
                            <font-awesome-icon :icon="['fab','facebook']" size="2x"/>
                        </a>
                        <a href="https://www.linkedin.com/company/kuida/" target="_blank" style="margin: 0 10px;">
                            <font-awesome-icon :icon="['fab','linkedin']" size="2x"/>
                        </a>
                    </v-row>
                    <v-row align="center" justify="center" style="padding-top: 20px;">
                        <v-btn plain small @click="$vuetify.goTo('#jumbotron-div')">INICIO</v-btn>
                        <v-btn plain small @click="$vuetify.goTo('#doctors-div')">ESPECIALISTAS</v-btn>
                        <v-btn plain small @click="$vuetify.goTo('#download-div')">NOSOTROS</v-btn>
                        <v-btn plain small @click="$vuetify.goTo('#pricing-div')">PRECIOS</v-btn>
                        <v-btn plain small @click="$vuetify.goTo('#users-div')">PACIENTES</v-btn>
                        <v-btn plain small href="https://kuida-docs.web.app/" target="_blank">DOCUMENTACIÓN</v-btn>
                    </v-row>
                    <v-row style="padding-top: 20px;">
                        <v-col align="center" justify="center">
                            <v-row align="center" justify="center">
                                <v-btn plain small href="" target="_blank">TÉRMINOS Y CONDICIONES</v-btn>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-btn plain small href="" target="_blank">AVISO DE PRIVACIDAD</v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row justify="space-between" style="padding: 40px 50px 0 50px;">
                        <p class="over-dark-content footer-text">Copyright
                            <font-awesome-icon :icon="['far','copyright']"/>
                            2022 Kuida
                        </p>
                        <p class="over-dark-content footer-text">contacto@kuida.io</p>
                        <p class="over-dark-content footer-text">Desarrollado por <a href="https://ideen.mx"
                                                                                     target="_blank">Ideen</a></p>
                    </v-row>
                </v-col>
            </v-footer>
        </v-main>

        <!-- MOBILE NAVBAR-->
        <v-app-bar class="hidden-md-and-up" app color="primary" dark flat id="navbar">
            <img class="mr-16" :src="require('../assets/Kuida_logo.png')" height="40"/>

            <spacer></spacer>

            <v-toolbar-items>
                <!-- <v-btn plain x-small href="https://doctors.kuida.io/" target="_blank">APP DOCTORES</v-btn> -->
                <v-btn class="ml-16" plain small href="https://app.kuida.io/" target="_blank">APP USUARIOS</v-btn>
            </v-toolbar-items>

        </v-app-bar>

        <!-- MOBILE CONTENT -->
        <v-main fluid class="hidden-md-and-up">
            <!-- JUMBOTRON -->
            <v-row class="jumbotron-div pt-16 pb-16 mt-n16" justify="center" align="center">
                <v-col style="padding-top: 0px;">
                    <v-container class="">
                        <h1 id="slogan">La plataforma perfecta para los especialistas exitosos</h1>
                        <h2 class="over-dark-content content-text mt-6 pa-4">
                            Ayudamos a los profesionales de la salud a digitalizar las tareas de
                            su consultorio y brindarle a sus pacientes atención personalizada.
                        </h2>
                    </v-container>
                </v-col>
                <v-col justify="center" align="center">
                    <img class="mr-3" :src="require('../assets/art/doctors.jpg')" height="180"/>
                </v-col>
            </v-row>

            <!-- DOCTORS APP INFO DIV -->
            <v-row class="doctorsinfo-div pt-16 pb-16" align="center" justify="center">
                <!-- DOCTORS APP INFO DIV -->
                <h1 class="subtitles over-dark-content">ESPECIALISTAS</h1>
                <v-row class="justify-center">
                    <!-- AGENDA -->
                    <v-col cols="12" md="4" align="center" class="info-card-col pa-6 pt-16">
                        <img
                            class="mb-6 svg"
                            src="../assets/icons/calendar-check-regular.svg"
                            height="96"
                        >
                        <h2 class="over-dark-content mb-3">AGENDA</h2>
                        <p class="over-dark-content content-text pl-6 pr-6 ">
                            Kuida potencializa la calidad del servicio para tus pacientes con
                            la mejor programación de citas y recordatorios para ayudar a
                            gestionar el flujo de pacientes.
                        </p>
                    </v-col>

                    <!-- ACCESIBILIDAD -->
                    <v-col cols="12" md="4" align="center" class="info-card-col pa-6">
                        <img
                            class="mb-6 svg"
                            src="../assets/icons/thumbs-up-regular.svg"
                            height="96"
                        >
                        <h2 class="over-dark-content mb-3">ACCESIBILIDAD</h2>
                        <p class="over-dark-content content-text  pl-6 pr-6">
                            Obtén actualizaciones en tiempo real y modifica los horarios
                            de atención disponibles desde cualquier dispositivo.
                        </p>
                    </v-col>

                    <!-- EXPEDIENTE INTELIGENTE -->
                    <v-col cols="12" md="4" align="center" class="info-card-col pa-6">
                        <img
                            class="mb-6 svg"
                            src="../assets/icons/users-solid.svg"
                            height="96"
                        >
                        <h2 class="over-dark-content mb-3">EXPEDIENTE INTELIGENTE</h2>
                        <p class="over-dark-content content-text  pl-6 pr-6">
                            Administramos todos tus expedientes para que estén
                            disponibles en el momento exacto de tus consultas. A
                            un click de distancia sin tener que buscarlos.
                        </p>
                    </v-col>
                </v-row>

                <v-row class="justify-center mt-16">
                    <a href="https://kuidadoctors.web.app" target="_blank">
                        <button
                            class="kuida-big-btn coral-btn button-shadow mt-n16 mb-16"
                            id="doctors-button"
                        >
                            Comenzar
                        </button>
                    </a>
                </v-row>
            </v-row>

            <!-- APP KEYNOTE DIV -->
            <v-row class="downloadapp-div pt-16 pb-16" align="center" justify="center">
                <v-row align="center" justify="center">
                    <h3 style="color: #5AC6C8; font-size: 1.5em;">CONOCE KUIDA</h3>

                    <iframe
                        style="width: 80%; height: 60vh;"
                        src="https://www.youtube.com/embed/lET7kZlnAUw"
                        title="Keynote Kuida"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </v-row>

                <v-row align="center" justify="center" class="mt-12">
                    <a href="https://kuida-users.web.app" target="_blank">
                        <button
                            class="no-uppercase kuida-big-btn coral-btn button-shadow"
                            id="download-button"
                        >
                            App para Pacientes
                        </button>
                    </a>
                </v-row>

                <v-row align="center" justify="center" class="mt-8">
                    <a href="https://kuidadoctors.web.app" target="_blank">
                        <button
                            class="kuida-big-btn aqua-btn button-shadow"
                            id="doctors-button"
                        >
                            App para Especialistas
                        </button>
                    </a>
                </v-row>
            </v-row>

            <!-- DOCTORS PRICES AND COMMISSIONS DIV -->
            <v-row class="pricesinfo-div pt-16 pb-16 pr-n4" align="center" justify="center">
                <h1 class="subtitles over-dark-content">PRECIOS</h1>

                <v-row class="justify-center">
                    <!-- STANDARD SUBSCRIPTION -->
                    <v-col cols="12" md="4" align="center" class="info-card-col pa-6">
                        <v-card class="mx-auto pt-6 align-content-center" width="300" height="300"
                                style="border-radius: 15px; align-content: center;">
                            <p class="subscription-title">
                                STANDARD
                            </p>

                            <p class="subscription-price">
                                $499.00 <span style="font-size: medium">MXN</span>
                            </p>

                            <p class="subscription-fee">
                                + 5% COMISIÓN POR CITA
                            </p>

                            <p class="subscription-time">
                                POR 3 MESES
                            </p>

                            <p class="subscription-period">
                                ( PAGO TRIMESTRAL )
                            </p>

                            <v-card-actions>
                                <!--                                    <v-btn color="primary" text @click="showStandard = !showStandard">BONOS POR REFERENCIAS</v-btn>-->

                                <!--                                    <v-spacer></v-spacer>-->

                                <!--                                    <v-btn icon @click="showStandard = !showStandard">-->
                                <!--                                        <v-icon>{{ showStandard ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>-->
                                <!--                                    </v-btn>-->
                            </v-card-actions>

                            <!--                                <v-expand-transition>-->
                            <!--                                    <div v-show="showStandard" style="background-color: white; z-index: 1000;">-->
                            <!--                                        <v-divider></v-divider>-->

                            <!--                                        <v-card-text>-->
                            <!--                                            I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.-->
                            <!--                                        </v-card-text>-->
                            <!--                                    </div>-->
                            <!--                                </v-expand-transition>-->
                        </v-card>
                    </v-col>

                    <!-- PREMIERE SUBSCRIPTION -->
                    <v-col cols="12" md="4" align="center" class="info-card-col pa-6">
                        <v-card class="mx-auto pt-6 align-content-center" width="300" height="300"
                                style="border-radius: 15px; align-content: center;">
                            <p class="subscription-title">
                                PREMIERE
                            </p>

                            <p class="subscription-price">
                                $1,399.00 <span style="font-size: medium">MXN</span>
                            </p>

                            <p class="subscription-fee">
                                + 5% COMISIÓN POR CITA
                            </p>

                            <p class="subscription-time">
                                POR 12 MESES
                            </p>

                            <p class="subscription-period">
                                ( PAGO ANUAL )
                            </p>

                            <v-card-actions>
                                <!--                                    <v-btn color="primary" text @click="showStandard = !showStandard">BONOS POR REFERENCIAS</v-btn>-->

                                <!--                                    <v-spacer></v-spacer>-->

                                <!--                                    <v-btn icon @click="showStandard = !showStandard">-->
                                <!--                                        <v-icon>{{ showStandard ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>-->
                                <!--                                    </v-btn>-->
                            </v-card-actions>

                            <!--                                <v-expand-transition>-->
                            <!--                                    <div v-show="showStandard" style="background-color: white; z-index: 1000;">-->
                            <!--                                        <v-divider></v-divider>-->

                            <!--                                        <v-card-text>-->
                            <!--                                            I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.-->
                            <!--                                        </v-card-text>-->
                            <!--                                    </div>-->
                            <!--                                </v-expand-transition>-->
                        </v-card>
                    </v-col>

                    <!-- PREMIERE + SUBSCRIPTION -->
                    <v-col cols="12" md="4" align="center" class="info-card-col pa-6">
                        <v-card class="mx-auto pt-6 align-content-center" width="300" height="300"
                                style="border-radius: 15px; align-content: center;">
                            <p class="subscription-title">
                                PREMIERE +
                            </p>

                            <p class="subscription-price">
                                $18,999.00 <span style="font-size: medium">MXN</span>
                            </p>

                            <p class="subscription-fee">
                                SIN COMISIÓNES POR CITA
                            </p>

                            <p class="subscription-time">
                                POR 12 MESES
                            </p>

                            <p class="subscription-period">
                                ( PAGO ANUAL )
                            </p>

                            <v-card-actions>
                                <!--                                    <v-btn color="primary" text @click="showStandard = !showStandard">BONOS POR REFERENCIAS</v-btn>-->

                                <!--                                    <v-spacer></v-spacer>-->

                                <!--                                    <v-btn icon @click="showStandard = !showStandard">-->
                                <!--                                        <v-icon>{{ showStandard ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>-->
                                <!--                                    </v-btn>-->
                            </v-card-actions>

                            <!--                                <v-expand-transition>-->
                            <!--                                    <div v-show="showStandard" style="background-color: white; z-index: 1000;">-->
                            <!--                                        <v-divider></v-divider>-->

                            <!--                                        <v-card-text>-->
                            <!--                                            I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.-->
                            <!--                                        </v-card-text>-->
                            <!--                                    </div>-->
                            <!--                                </v-expand-transition>-->
                        </v-card>
                    </v-col>
                </v-row>

                <v-row class="justify-center">
                    <a href="https://kuidadoctors.web.app" target="_blank">
                        <button
                            class="kuida-big-btn coral-btn button-shadow mt-16 mb-16"
                            id="doctors-button"
                        >
                            Registrate
                        </button>
                    </a>
                </v-row>
            </v-row>

            <!-- USERS APP INFO DIV -->
            <v-row class="usersapp-div pt-16 pb-16" align="center" justify="center">
                <h1 class="subtitles over-dark-content">PACIENTES</h1>
                <v-row class="justify-center">
                    <!-- SEGURIDAD -->
                    <v-col cols="12" md="4" align="center" class="info-card-col pa-6 pt-16">
                        <img
                            class="mb-6 svg"
                            src="../assets/icons/user-shield-solid.svg"
                            height="96"
                        >
                        <h2 class="over-dark-content mb-3">SEGURIDAD</h2>
                        <p class="over-dark-content content-text pl-6 pr-6">
                            Accede a información confiable y profesionales de la salud con
                            perfiles previamente varificados. Podrás ver su perfil completo,
                            certificaciones y consultorios.
                        </p>
                    </v-col>

                    <!-- VELOCIDAD -->
                    <v-col cols="12" md="4" align="center" class="info-card-col pa-6">
                        <img
                            class="mb-6 svg"
                            src="../assets/icons/calendar-alt-regular.svg"
                            height="96"
                        >
                        <h2 class="over-dark-content mb-3">VELOCIDAD</h2>
                        <p class="over-dark-content content-text pl-6 pr-6">
                            Agenda, reagenda o cancela tus citas en menos de 30 segundos
                            con nuestros profesionales desde cualquier dispositivo.
                        </p>
                    </v-col>

                    <!-- EFICIENCIA -->
                    <v-col align="center" cols="12" md="4" class="info-card-col pa-6">
                        <img
                            class="mb-6 svg"
                            src="../assets/icons/apple-alt-solid.svg"
                            height="96"
                        >
                        <h2 class="over-dark-content mb-3">EFICIENCIA</h2>
                        <p class="over-dark-content content-text pl-8 pr-8">
                            Escoge la mejor opción en tu zona para tus necesidades, obten
                            recordatorios para nunca faltar a tus consultas y lleva
                            el siguimiento de tus síntomas o tratamientos desde la
                            misma aplicación.
                        </p>
                    </v-col>
                </v-row>
                <v-row class="justify-center mt-16">
                    <a href="https://kuida-users.web.app" target="_blank">
                        <button
                            class="no-uppercase kuida-big-btn aqua-btn button-shadow mt-n16 mb-16"
                            id="download-button"
                        >
                            Conoce nuestra app
                        </button>
                    </a>
                </v-row>
            </v-row>

            <!-- FOOTER -->
            <v-footer padless style="height: auto;" class="mb-6">
                <v-col fill-height>
                    <v-row align="center" justify="center" style="padding-top: 50px;">
                        <a href="https://www.instagram.com/kuida.io/?hl=es-la" target="_blank" style="margin: 0 10px;">
                            <font-awesome-icon :icon="['fab','instagram']" size="2x"/>
                        </a>
                        <a href="#" target="_blank" style="margin: 0 10px;">
                            <font-awesome-icon :icon="['fab','facebook']" size="2x"/>
                        </a>
                        <a href="https://www.linkedin.com/company/kuida/" target="_blank" style="margin: 0 10px;">
                            <font-awesome-icon :icon="['fab','linkedin']" size="2x"/>
                        </a>
                    </v-row>
                    <v-row align="center" justify="center" style="padding-top: 20px;">
                        <v-btn plain medium @click="$vuetify.goTo('#jumbotron-div')">INICIO</v-btn>
                        <v-btn plain medium @click="$vuetify.goTo('#doctors-div')">ESPECIALISTAS</v-btn>
                        <v-btn plain medium @click="$vuetify.goTo('#download-div')">NOSOTROS</v-btn>
                        <v-btn plain medium @click="$vuetify.goTo('#pricing-div')">PRECIOS</v-btn>
                        <v-btn plain medium @click="$vuetify.goTo('#users-div')">PACIENTES</v-btn>
                        <v-btn plain medium href="https://kuida-docs.web.app/" target="_blank">DOCUMENTACIÓN</v-btn>
                    </v-row>

                    <v-row style="padding-top: 20px;">
                        <v-col align="center" justify="center">
                            <v-row align="center" justify="center">
                                <v-btn plain small href="" target="_blank">TÉRMINOS Y CONDICIONES</v-btn>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-btn plain small href="" target="_blank">AVISO DE PRIVACIDAD</v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row justify="center" align="center" style="padding: 40px 10px 0 10px;">
                        <p class="over-dark-content footer-text mr-6 ml-6" align="center">Copyright
                            <font-awesome-icon :icon="['far','copyright']"/>
                            2022 Kuida
                        </p>
                        <br>
                        <p class="over-dark-content footer-text mr-6 ml-6">contacto@kuida.io</p>
                        <br>
                        <p class="over-dark-content footer-text mr-6 ml-6">
                            Desarrollado por <a href="https://ideen.mx" target="_blank">Ideen</a>
                        </p>
                    </v-row>
                </v-col>
            </v-footer>
        </v-main>

        <!-- MISSING ID FILE BOTTOM SHEET -->
        <v-bottom-sheet v-model="onlyDesktop">
            <v-sheet class="text-center" height="300px">
                <v-btn class="mt-6" text color="primary" @click="onlyDesktop = false">
                    CERRAR
                </v-btn>
                <div class="pa-6">
                    La plataforma de especialistas solo está disponible para navegadores de computadoras, próximamente
                    podrás usarlas en tu dispositivo móvil.
                </div>
            </v-sheet>
        </v-bottom-sheet>

        <!-- PRE LAUNCH MESSAGE -->
        <v-bottom-sheet v-model="whiteListInvite">
            <v-sheet class="text-center" height="300px">
                <v-btn class="mt-6" text color="primary" @click="whiteListInvite = false">
                    CERRAR
                </v-btn>
                <div class="pa-6">
                    ¡Muy pronto podras registrarte en Kuida! Espera noticias sobre nuestro lanzamiento oficial.
                </div>
            </v-sheet>
        </v-bottom-sheet>

        <!-- JOIN TO DISCORD -->
<!--        <v-dialog v-model="dialog" width="500" persistent>
            <v-card>
                <v-card-title class="text-h5 accent white&#45;&#45;text">
                    ¡Únete a nuestra comunidad!
                </v-card-title>

                <v-container class="mt-1">
                    <h2 style="color: darkgrey;">
                        Participa en la mejor comunidad de especialistas de la salud en México.
                    </h2>

                    <h1 style="color: #3ec6c6;">
                        ¡TOTALMENTE GRATIS!
                    </h1>

                    <v-card-text>
                        Únete a los
                        cientos de especialistas que conforman la comunidad Kuida y participa por pertenecer al grupo
                        VIP para obtener exclusivos beneficios que podrás usar en nuestra plataforma.
                    </v-card-text>
                </v-container>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="grey"
                        text
                        @click="dialog = false"
                    >
                        Omitir ahora
                    </v-btn>
                    <v-spacer></v-spacer>

                    <a href="https://discord.gg/KDbEaq2znr" target="_blank" style="text-decoration: none;">
                        <v-btn color="#3ec6c6" text>
                            Unirme en Discord
                            <font-awesome-icon :icon="['fab','discord']" size="1x"/>
                        </v-btn>
                    </a>

                </v-card-actions>
            </v-card>
        </v-dialog>-->

    </v-app>
</template>

<script>
import SvgFiller from 'vue-svg-filler'

export default {
    name: "LandingPage",
    components: [
        SvgFiller
    ],
    data() {
        return {
            onlyDesktop: false,
            showStandard: false,
            showPremiere: false,
            showPremierePlus: false,
        }
    },
    created() {
        console.log("Hey there!");
    }
}
</script>

<style scoped>
#slogan {
    font-size: 2em !important;
}

.content-text {
    color: white;
    text-align: justify;
    font-size: 17px;
}

.svg {
    filter: brightness(0) invert(1);
}

.footer-text {
    font-size: 15px;
}

.subscription-title {
    text-align: center;
    font-weight: bolder;
    font-size: xx-large;
    color: #f96767;
    font-family: 'Montserrat', sans-serif;
}

.subscription-price {
    text-align: center;
    font-weight: bold;
    font-size: xx-large;
    color: #3ec6c6;
    font-family: 'Montserrat', sans-serif;
}

.subscription-fee {
    margin-top: -15px;
    margin-bottom: 35px;
    text-align: center;
    font-weight: bold;
    font-size: medium;
    color: #3ec6c6;
    font-family: 'Montserrat', sans-serif;
}

.subscription-time {
    text-align: center;
    font-weight: bold;
    font-size: medium;
    color: #3ec6c6;
    font-family: 'Montserrat', sans-serif;
}

.subscription-period {
    margin-top: -15px;
    text-align: center;
    font-weight: bold;
    font-size: small;
    color: #f96767;
    font-family: 'Montserrat', sans-serif;
}


/* Designing for scroll-bar */
::-webkit-scrollbar {
    width: 0;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f96767;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #f96767;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #f96767;
}

@media only screen and (max-width: 700px) {
    #slogan {
        font-size: 1.6em !important;
    }

    .content-text {
        color: white;
        text-align: justify;
        font-size: 15px;
    }

    .subtitles {
        font-size: 35px;
    }

    .jumbotron-div {
        background-color: #f96767;
        height: auto;
        width: 100%;
        padding: 0;
    }

    .usersapp-div {
        background-color: #f96767;
        height: auto;
        width: 100%;
        padding: 0;
    }

    .downloadapp-div {
        background-color: white;
        height: auto;
        width: 100%;
        padding: 0;
    }

    .doctorsinfo-div {
        background-color: #5AC6C8;
        height: auto;
        width: 100%;
        padding: 0;
    }

    .pricesinfo-div {
        background-color: #5AC6C8;
        height: auto;
        width: 100%;
        padding: 0;
    }


    /* Designing for scroll-bar */
    ::-webkit-scrollbar {
        width: 0;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f96767;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #f96767;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #f96767;
    }
}
</style>